.flex-one { flex: 1; }

.ph-bg-imperial { background-color: #004C66; }
.ph-bg-electric { background-color: #7CFEF0; }
.ph-bg-gainsboro { background-color: #D3DDE8; }
.ph-bg-green { background-color: #457C8F; }
.ph-green { color: #457C8F; }
.ph-electric { color: #7CFEF0; }
.ph-mindaro { color: #DBFE87; }
.ph-bg-dark-moss { background-color: #505D32; }

.ph-turquoise { color: #66D0C5; }

.ph-gainsboro { color: #D3DDE8; }

.ph-imperial { color: #004C66; }

.ph-dark-moss { color: #505D32; }


.ph-b--electric { border-color: #7CFEF0; }
.ph-b--mindaro { border-color: #DBFE87; }
.ph-b--imperial { border-color: #004C66; }

.ph-b--turquoise { border-color: #66D0C5; }

.ph-b--dark-moss { border-color: #505D32; }


.ph-hover-electric:hover { color: #7CFEF0; }
.ph-hover-electric:focus { color: #7CFEF0; }

.ph-hover-bg-gainsboro:hover { background-color: #D3DDE8; }
.ph-hover-bg-gainsboro:focus { background-color: #D3DDE8; }
.ph-hover-imperial:hover { color: #004C66; }
.ph-hover-imperial:focus { color: #004C66; }


.ph-hover-b--gainsboro:hover { border-color: #D3DDE8;}
.ph-hover-b--gainsboro:focus { border-color: #D3DDE8;}
